<template>
  <div>
    <el-dialog
        title="品牌等级"
        width="50%"
        :visible.sync="states"
        :modal-append-to-body="false"
        @close="close">
      <el-row>
        <el-button type="primary" @click="brandGradeAddOpen">添加品牌等级</el-button>
      </el-row>
      <el-row style="margin-top: 20px">
        <el-table
            :data="brandGradeArray"
            border
            max-height="260"
            style="width: 100%">
          <el-table-column prop="gradeName" label="等级名称" align="center"></el-table-column>
          <el-table-column label="是否激活" align="center">
            <template #default="scope">
              <el-switch
                  @change="isChange(scope.row)"
                  v-model="scope.row.isValid"
                  :active-value="1"
                  :inactive-value="0"
                  active-color="#13ce66"
                  inactive-color="#ff4949">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template #default="scope">
              <el-button type="primary" size="mini" @click="brandGradeEditOpen(scope.row)">修改</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row>
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-size="100"
            layout="prev, pager, next,total, jumper, sizes"
            :total="total">
        </el-pagination>
      </el-row>
    </el-dialog>
    <brand-grade-add v-if="brandGradeAddState" :state="brandGradeAddState" @close="brandGradeAddClose"></brand-grade-add>
    <brand-grade-edit v-if="brandGradeEditState" :state="brandGradeEditState" @close="brandGradeEditClose" :brandGrade="brandGrade"></brand-grade-edit>
  </div>
</template>

<script>
import brandGradeAdd from "@/pages/style/brand/brand-grade-add"
import brandGradeEdit from "@/pages/style/brand/brand-grade-edit"
export default {
  name: "brand-grade-list",
  components: {
    brandGradeAdd,
    brandGradeEdit
  },
 created() {
    this.pageInit()
  },
  props: {
    state: {
      type: Boolean,
      required: true,
      default: true,
    }
  },
  data() {
    return {
      states: this.state,
      brandGradeArray: [],
      total: 0,
      page: 1,
      limit: 100,
      brandGradeAddState: false,
      brandGradeEditState: false,
      brandGrade: {},
    }
  },
  methods: {
    pageInit() {
      this.queryBrandGradeList()
    },
    queryBrandGradeList() {
      this.$axios({
        method: 'GET',
        url: "/styleBrand/brandGradeList",
        params: {
          tenantCrop: localStorage.getItem("tenantCrop")
        }
      }).then(response => {
        this.brandGradeArray = response.data.data.list
        this.total = response.data.data.total
      })
    },
    handleSizeChange(val) {
      this.limit = val
      this.queryBrandGradeList();
    },
    handleCurrentChange(val) {
      this.page =val
      this.queryBrandGradeList()
    },
    close() {
      this.$emit("close");
    },
    isChange(val) {
      this.$axios({
        method: "POST",
        url: "/styleBrand/updateBrandGrade",
        params: {
          id: val.id,
          isValid: val.isValid,
        }
      }).then(response => {
        let flag = response.data.code === 200
        this.$message({
          showClose: true,
          message: flag ? "修改成功" :response.data.msg,
          type: flag? 'success' :'error',
          duration: 1000,
        })
        this.queryBrandGradeList()
      })
    },
    brandGradeAddOpen() {
      this.brandGradeAddState = true
    },
    brandGradeAddClose() {
      this.brandGradeAddState = false
      this.queryBrandGradeList()
    },
    brandGradeEditOpen(val) {
      this.brandGrade = val
      this.brandGradeEditState = true
    },
    brandGradeEditClose() {
      this.brandGradeEditState = false
      this.queryBrandGradeList()
    }
  }
}
</script>

<style scoped>

</style>