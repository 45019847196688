<template>
  <el-dialog
      title="添加品牌等级"
      width="30%"
      :visible.sync="states"
      append-to-body
      @close="close">
    <el-form label-width="110px" :model="form" :rules="rules" ref="form">
      <el-row>
        <el-col :span="20" :offset="1">
          <el-form-item label="品牌等级名称" prop="gradeName">
            <el-input v-model="form.gradeName" placeholder="请填写品牌名称"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="submit">提 交</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "brand-grade-edit",
  props: {
    state: {
      type: Boolean,
      required: true,
      default: true,
    },
    brandGrade: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      states: this.state,
      form: {
        gradeName: this.brandGrade.gradeName,
      },
      rules:{
        gradeName: [{required: true, message: '请填写品牌名称', trigger: 'blur'}],
      }
    }
  },
  methods: {
    submit() {
      this.$refs['form'].validate(valid=> {
        if (valid) {
          this.$axios({
            method: "POST",
            url: "/styleBrand/updateBrandGrade",
            params: {
              id: this.brandGrade.id,
              ...this.form
            }
          }).then(response=>{
            let flag = response.data.code === 200
            this.$message({
              showClose: true,
              message: flag ? '等级名称修改成功': response.data.msg,
              type: flag ? 'success':'error',
              duration: 1000,
            });
            if (flag) {
              this.close()
            }
          })
        }
      })
    },
    close() {
      this.$emit("close");
    },
  }
}
</script>

<style scoped>

</style>