<template>
  <el-dialog
      title="添加品牌等级"
      width="30%"
      :visible.sync="states"
      append-to-body
      @close="close">
    <el-form label-width="110px" :model="form" :rules="rules" ref="form">
      <el-row>
        <el-col :span="20" :offset="1">
          <el-form-item label="品牌等级名称" prop="gradeName">
            <el-input v-model="form.gradeName" placeholder="请填写品牌名称"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="submit">提 交</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "brand-grade-add",
  props: {
    state: {
      type: Boolean,
      required: true,
      default: true,
    }
  },
  data() {
    return {
      states: this.state,
      form: {
        gradeName: "",
      },
      rules:{
        gradeName: [{required: true, message: '请填写品牌名称', trigger: 'blur'}],
      }
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    submit() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.$alert("确认要添加吗？","品牌等级添加",{
            dangerouslyUseHTMLString: true,
            type: "success",
            showCancelButton: true
          }).then(()=>{
            this.$axios({
              method: "POST",
              url: "/styleBrand/saveBrandGrade",
              params: {
                tenantCrop: localStorage.getItem("tenantCrop"),
                ...this.form
              }
            }).then(response => {
              let flag = response.data.code === 200
              this.$message({
                showClose: true,
                message: flag ? '品牌等级添加成功': response.data.msg,
                type: flag ? 'success':'error',
                duration: 1000,
              });
              if (flag) {
                this.close()
              }
            })
          })
        }
      })
    },
  },
}
</script>

<style scoped>

</style>